import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";

// API Configuration

// GIPHY API
// @see https://developers.giphy.com/docs/api/endpoint#trending
// @see https://api.giphy.com/v1/gifs/trending?api_key=YfKFvEqlqmymcVI88PMbrlzG8QwAoIhh&limit=5&offset=0&rating=g
const GIPHY_API_KEY = "YfKFvEqlqmymcVI88PMbrlzG8QwAoIhh"; 
const GIPHY_BASE_URL = "https://api.giphy.com/v1/gifs/trending";

// Tenor API
// @see https://tenor.com/gifapi/documentation#endpoints-trendinggifs
// @see https://g.tenor.com/v1/trending?key=LIVDSRZULELA&limit=5&pos=&contentfilter=high
const TENOR_API_KEY = "LIVDSRZULELA"; 
const TENOR_BASE_URL = "https://g.tenor.com/v1/trending";

// API Function
const fetchGiphyGifs = async (limit = 10, offset = 0) => {
  const response = await axios.get(GIPHY_BASE_URL, {
    params: {
      api_key: GIPHY_API_KEY,limit,offset,rating: "g",
    },
  })

  return response.data.data.map((gif) => ({
    id: gif.id,
    url: gif.url,
    title: gif.title,
    image: gif.images.fixed_height.url,
    source: "GIPHY",
  }));
};

const fetchTenorGifs = async (limit = 10, pos = "") => {
  const response = await axios.get(TENOR_BASE_URL, {
    params: {
      key: TENOR_API_KEY,limit,pos,contentfilter: "high",
    },
  })

  return {
    results: response.data.results.map((gif) => ({
      id: gif.id,
      url: gif.itemurl,
      title: gif.title,
      image: gif.media[0].gif.url,
      source: "Tenor",
    })),
    next: response.data.next,
  }
};

// Components
const GiphyItem = ({ gif }) => {
  return (
    <div className="gif-item">
      <a href={gif.url} target="_blank" rel="noopener noreferrer">
        <img src={gif.image} height={100}/>
      </a>
    </div>
  );
};

const TenorItem = ({ gif }) => {
  return (
    <div className="gif-item">
      <a href={gif.url} target="_blank" rel="noopener noreferrer">
        <img src={gif.image} height={100}/>
      </a>
    </div>
  );
};

const GifList = ({ gifs }) => {
  return (
    <div className="gif-list">
      {gifs.map((gif) => 
        gif.source === "Giphy" ? (
          <GiphyItem key={`giphy-${gif.id}`} gif={gif} />
        ) : (
          <TenorItem key={`tenor-${gif.id}`} gif={gif} />
        )
      )}
    </div>
  )
};

const LoadMoreButton = ({ onClick, disabled, loading }) => {
  return (
    <div className="load-more">
      {!disabled ? (
        <button onClick={onClick} >
          {loading ? "Loading..." : "Load More!"}
        </button>
      ) : (
        <p>Maximum number loaded.</p>
      )}
    </div>
  );
};

// Main App Component
const App = () => {
  const [gifs, setGifs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [giphyOffset, setGiphyOffset] = useState(0);
  const [tenorPos, setTenorPos] = useState("");
  const maxGifs = Infinity;

  const loadGifs = async () => {
    if (gifs.length >= maxGifs) return;

    setLoading(true);
    
    const giphyGifs = await fetchGiphyGifs(5, giphyOffset);
    const { results: tenorGifs, next: tenorNext } = await fetchTenorGifs(
      5,
      tenorPos
    );
    const newGifs = [...giphyGifs, ...tenorGifs].sort(
      () => Math.random() - 0.5
    );

    setGifs((prevGifs) => [...prevGifs, ...newGifs]);
    setGiphyOffset((prevOffset) => prevOffset + 5);
    setTenorPos(tenorNext);
    setLoading(false);
  };

  useEffect(() => {
    loadGifs();
  }, []);

  return (
    <div className="App">
      <div className="header">Trending GIFs</div>
      <GifList gifs={gifs} />
      <LoadMoreButton
        onClick={loadGifs}
        disabled={gifs.length >= maxGifs}
        loading={loading}
      />
    </div>
  );
}

export default App;
